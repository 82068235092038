var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    initEmailSignup: function (templateContainer) {
      if (!templateContainer.length) {
        return null;
      }

      templateContainer.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form')
          ? $emailContainerNode
          : $('form', $emailContainerNode);
        var $emailError = $('.js-email-signup__error', $emailContainerNode);
        var $TermsAndConditionsError = $('.js-email-terms__error', $emailContainerNode);
        var $locInput = $('input[name="location"]', $emailContainerNode);
        var $emailAddress = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $emailSubmit = $('.site-email-signup__submit', $emailContainerNode);
        var $termsAndConditions = $('.js-email-terms-conditions', $emailContainerNode);
        var $signupHeaders = $(
          '.js-email-signup-header, .js-email-signup-legal',
          $emailContainerNode
        );
        var $languageIDInput = $('input[name="LANGUAGE_ID"]', $emailContainerNode);
        var $lastSource = $('input[name="LAST_SOURCE"]', $emailContainerNode);
        var $requriedFields = $(
          'input[name="PC_EMAIL_ADDRESS"], input[name="BIRTH_MONTH"], input[name="BIRTH_DAY"]',
          $emailContainerNode
        );
        var $emailPromotions = $('input[name="PC_EMAIL_PROMOTIONS"]', $emailContainerNode);
        var $emailRedirect = $('.js-redirect-email-signup', $emailContainerNode);
        var selected_locale = generic.cookie('LOCALE');
        var languageSettings = Drupal.settings.common.translation_locales;

        // Updating the Language ID after the site is translated
        if (typeof selected_locale !== 'undefined' && languageSettings) {
          var language_id = languageSettings[selected_locale];

          if (language_id) {
            $languageIDInput.val(language_id);
          }
        }

        var last_source_val = $lastSource.val();

        if (last_source_val === 'gnav' && $emailContainerNode.hasClass('offer-email-signup')) {
          $lastSource.val('offers_page');
        }

        var location_val = $locInput.val();

        if ($emailContainerNode.hasClass('js-email-signup-sticky')) {
          $signupHeaders.addClass('hidden');
          $emailAddress.attr('placeholder', $emailContainerNode.data('sticky-text'));
        }

        // activate submit when forms are complete.
        $requriedFields.once().on('blur, keyup', function () {
          var emptyFields = false;

          $requriedFields.each(function () {
            if ($.trim($(this).val()) === '') {
              emptyFields = true;
            }
          });

          if (emptyFields) {
            $emailSubmit.addClass('disabled');
          } else {
            $emailSubmit.removeClass('disabled');
          }
        });

        $emailForm.once('js-email-signup__form').on('submit', function (submitEvt) {
          if ($emailSubmit.hasClass('disabled')) {
            submitEvt.preventDefault();

            return;
          }
          submitEvt.preventDefault();
          var destinationPath;
          var emailValue;
          var emailPromotion;

          if (Drupal.settings.elc_common.elc_site_type.coresite) {
            destinationPath = $emailRedirect.length ? $emailRedirect.val() : '';
            emailValue = $emailAddress.val();
            emailPromotion = $termsAndConditions.length ? $emailPromotions.prop('checked') : 1;
            if (!$emailError.hasClass('hidden')) {
              $emailError.addClass('hidden');
            }
            if ($TermsAndConditionsError.length && !$TermsAndConditionsError.hasClass('hidden')) {
              $TermsAndConditionsError.addClass('hidden');
            }
            if (emailValue !== '' && destinationPath !== '' && emailPromotion) {
              destinationPath = destinationPath + '?email=' + encodeURIComponent(emailValue);
              window.location.href = destinationPath;
            } else {
              if (emailValue === '') {
                $emailError.removeClass('hidden');
              }
              if ($TermsAndConditionsError.length && !emailPromotion) {
                $TermsAndConditionsError.removeClass('hidden');
              }
            }

            return false;
          }
          var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);

          $emailSuccess.add($emailError).addClass('hidden');
          $emailForm.find('.error').removeClass('error');
          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });
          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var data = jsonRpcResponse.getData();
              var alreadySignedUp = data.userinfo.previously_opted_in_email_promotion;
              var alreadyPurchased = data.userinfo.previously_purchased;
              var $existingUserSuccessMsg = $('.js-email-signup__existing-user', $emailSuccess);
              var $newUserSuccessMsg = $('.js-email-signup__new-user', $emailSuccess);

              if (alreadySignedUp || alreadyPurchased) {
                $existingUserSuccessMsg.show();
                $newUserSuccessMsg.hide();
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $existingUserSuccessMsg.hide();
                $newUserSuccessMsg.show();
                $(document).trigger('email_signup:success_and_new', location_val);
              }
              $emailForm.trigger('reset');
              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                generic.overlay.launch({
                  height: 'auto',
                  html: $emailSuccess.html()
                });
              }
            },
            onFailure: function (jsonRpcResponse) {
              var messages = jsonRpcResponse.getMessages();

              $emailError.empty().removeClass('hidden');
              $emailContainerNode.find('.error').removeClass('error');
              $.each(messages, function (index) {
                var errorInputfield = messages[index].tags[2].split('field.')[1];

                $('[name="' + errorInputfield.toUpperCase() + '"]', $emailContainerNode)
                  .addClass('error')
                  .next('.selectBox')
                  .addClass('error');
                $emailError.append('<div>' + messages[index].text + '</div>').addClass('error');
              });
            }
          });
        });

        if (!!$termsAndConditions.length && $termsAndConditions.hasClass('slider')) {
          // hide terms + conditions by default
          $termsAndConditions.slideUp(300, function () {
            Drupal.behaviors.grids.equalHeights($emailContainerNode);
          });

          // Show email sign up copy on focus
          $emailAddress.off('focus').on('focus', function () {
            $termsAndConditions.slideDown(300, function () {
              Drupal.behaviors.grids.equalHeights($emailContainerNode);
            });
          });
        }
      });
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      var templateContainer = $('.js-email-signup', context);

      site.emailSignup.initEmailSignup(templateContainer);

      var emailSignUpStickyContainer = $('.js-email-signup-sticky', context);

      if (emailSignUpStickyContainer.length > 0) {
        var emailSignUpStickyForm = $('form', emailSignUpStickyContainer);
        var emailSignUpStickyHeaders = $(
          '.js-email-signup-header, .js-email-signup-legal',
          emailSignUpStickyContainer
        );
        var emailSignUpStickyEmailField = $(
          'input[name="PC_EMAIL_ADDRESS"]',
          emailSignUpStickyContainer
        );
        var emailSignUpStickyError = $('.js-email-signup__error', emailSignUpStickyContainer);
        var emailFieldErrorFlag = false;
        var emailFieldErrorVal = '';

        emailSignUpStickyForm.mouseenter(function () {
          emailSignUpStickyHeaders.removeClass('hidden');
          emailSignUpStickyEmailField.attr(
            'placeholder',
            emailSignUpStickyEmailField.data('label')
          );
          if (emailSignUpStickyError.hasClass('hidden') && !!emailSignUpStickyError.html()) {
            emailSignUpStickyError.removeClass('hidden');
          }
          if (emailFieldErrorFlag === true) {
            emailSignUpStickyEmailField.addClass('error');
            emailSignUpStickyEmailField.val(emailFieldErrorVal);
            emailFieldErrorFlag = false;
            emailFieldErrorVal = '';
          }
        });

        emailSignUpStickyForm.mouseleave(function () {
          emailSignUpStickyHeaders.addClass('hidden');
          emailSignUpStickyEmailField.attr(
            'placeholder',
            emailSignUpStickyContainer.data('sticky-text')
          );
          if (!emailSignUpStickyError.hasClass('hidden')) {
            emailSignUpStickyError.addClass('hidden');
          }
          if (emailSignUpStickyEmailField.hasClass('error')) {
            emailSignUpStickyEmailField.removeClass('error');
            emailFieldErrorFlag = true;
            emailFieldErrorVal = emailSignUpStickyEmailField.val();
            emailSignUpStickyEmailField.val('');
          }
        });
      }

      // hide terms + conditions when clicked outside
      $(document).on('click', function (hideEvt) {
        templateContainer.each(function () {
          var $emailContainerNode = $(this);
          var $emailForm = $emailContainerNode.is('form')
            ? $emailContainerNode
            : $('form', $emailContainerNode);
          var $termsAndConditions = $('.js-email-terms-conditions', $emailContainerNode);

          if (!$(hideEvt.target).closest($emailForm).length && !$(hideEvt.target).is($emailForm)) {
            if (
              !(
                $(hideEvt.target).closest($('form').find('input[name="PC_EMAIL_PROMOTIONS"]')) ===
                $emailForm.find('input[name="PC_EMAIL_PROMOTIONS"]')
              ) &&
              !!$termsAndConditions.length &&
              $termsAndConditions.hasClass('slider') &&
              $termsAndConditions.is(':visible')
            ) {
              $termsAndConditions.slideUp(300, function () {
                Drupal.behaviors.grids.equalHeights($emailContainerNode);
              });
            }
          }
        });
      });
    }
  };
})(jQuery);
